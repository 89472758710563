'use client';
import type { MouseEvent } from 'react';
import { useRouter } from 'next/navigation';
import type { ProductDataType } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';

type HeroFullCardProps = {
  overrideProductsCallToActionUrl?: string;
  data: ProductDataType;
  callToActionText?: string;
  learnMoreText?: string;
};

export const HeroFullCard = ({
  data,
  overrideProductsCallToActionUrl,
  callToActionText,
  learnMoreText,
}: HeroFullCardProps) => {
  const router = useRouter();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (data.callToAction?.url && !(e.target instanceof HTMLAnchorElement)) {
      router.push(data.callToAction.url);
    }
  };

  return (
    <div
      className="wf-group wf-relative wf-w-[13.75rem] wf-shrink-0 wf-font-gothic hover:wf-cursor-pointer"
      onClick={handleClick}>
      <img
        src={data.image?.url}
        alt={data.image?.alt}
        className="wf-absolute wf-left-1/2 wf-top-0 wf-z-10 wf-max-w-[7.5rem] -wf-translate-x-1/2 -wf-translate-y-[40%] wf-object-contain wf-drop-shadow-[0_20px_20px_rgba(0,_0,_0,_0.3)] wf-transition-[transform_0.3s_ease,_filter_0.3s_ease] group-hover:-wf-translate-y-[65%] group-hover:wf-drop-shadow-[0_40px_30px_rgba(0,_0,_0,_0.3)]"
      />
      <div className="wf-rounded wf-border wf-border-rdsn-gray-400 wf-bg-rdsn-white wf-pb-4 wf-pl-4 wf-pr-2.5 wf-pt-[8.1875rem] wf-text-rdsn-black wf-transition-transform group-hover:wf-scale-[1.02] group-hover:wf-border-rdsn-black">
        <div className="wf-max-w-[12.0625rem] wf-space-y-4">
          <div className="wf-space-y-2">
            <h3 className="wf-text-rdsn-prod">{data.name}</h3>
            <span className="wf-text-rdsn-prod-sub">{data.medicationName}</span>
          </div>
          <p className="wf-text-rdsn-body-sm">{data.description}</p>
          <div className="wf-flex wf-gap-x-2">
            <Button
              size="small"
              variant="primary"
              className="wf-w-full wf-shrink wf-px-0"
              asChild>
              <a
                href={
                  (overrideProductsCallToActionUrl || data.callToAction?.url) ??
                  ''
                }>
                {callToActionText ?? data.callToAction?.text}
              </a>
            </Button>
            {learnMoreText && (
              <Button
                size="small"
                variant="secondary"
                className="wf-px-2.5"
                asChild>
                <a href={data.productSlug ?? ''}>{learnMoreText}</a>
              </Button>
            )}
          </div>
          {data.safetyInformationUrl && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="wf-inline-block wf-text-rdsn-cap wf-text-rdsn-gray-800 wf-underline"
              href={data.safetyInformationUrl}>
              Important safety information
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

'use client';
import { useState } from 'react';
import type { CategorizedFaqCategory } from '@strapi-schema';
import { CategoriesList } from '@/redesign/components/static-pages/CategorizedFaqContainer/CategoriesList';
import { CategoryFaq } from './CategoryFaq';

type CategorizedFaqContainerContentProps = {
  data: CategorizedFaqCategory[];
};

export const CategorizedFaqContainerContent = ({
  data,
}: CategorizedFaqContainerContentProps) => {
  const [activeCategory, setActiveCategory] = useState<CategorizedFaqCategory>(
    data[0],
  );

  const handleCategoryChange = (category: CategorizedFaqCategory) => {
    setActiveCategory(category);
  };

  return (
    <div className="max-md:wf-space-y-15 md:wf-flex md:wf-justify-between md:wf-gap-x-8">
      <CategoriesList
        data={data}
        activeCategory={activeCategory}
        onCategoryChange={handleCategoryChange}
      />
      <div className="wf-max-w-xl wf-grow wf-space-y-[1.6875rem]">
        <h3 className="wf-text-rdsn-h2 [&]:wf-leading-none">
          {activeCategory.name}
        </h3>
        <CategoryFaq data={activeCategory} />
      </div>
    </div>
  );
};

'use client';

import * as React from 'react';
import { ProductsContainerType } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import { CheckmarkIcon } from '@wearemotivated/design-system/redesign/icons/Checkmark';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { IconButton } from '@wearemotivated/design-system/redesign/components/IconButton';

export type CarouselProps = {
  overrideProductsCallToActionUrl?: string;
  callToActionText?: string;
  items: ProductsContainerType['products'];
  slug?: string;
  className?: string;
};

export const Carousel = ({
  overrideProductsCallToActionUrl,
  callToActionText,
  items,
  className,
}: CarouselProps) => {
  const [curIndex, setCurIndex] = React.useState<number>(0);
  const itemWidth = 250; // Fixed item width
  const gap = 16; // Gap between items

  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [carouselWidth, setCarouselWidth] = React.useState<number>(0);
  const [touchStartX, setTouchStartX] = React.useState<number | null>(null);
  const [touchStartY, setTouchStartY] = React.useState<number | null>(null);
  const [isSwiping, setIsSwiping] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (carouselRef.current) {
      const updateCarouselWidth = () => {
        setCarouselWidth(carouselRef.current!.offsetWidth);
      };

      updateCarouselWidth();
      window.addEventListener('resize', updateCarouselWidth);
      return () => window.removeEventListener('resize', updateCarouselWidth);
    }
  }, [items.length]);

  const HandlePrev = () => {
    setCurIndex((prev) => (prev - 1 < 0 ? items.length - 1 : prev - 1));
  };

  const HandleNext = () => {
    setCurIndex((prev) => (prev + 1 > items.length ? 0 : prev + 1));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX !== null && touchStartY !== null) {
      const deltaX = e.touches[0].clientX - touchStartX;
      const deltaY = e.touches[0].clientY - touchStartY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        e.preventDefault();
        setIsSwiping(true);
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (touchStartX !== null && touchStartY !== null && isSwiping) {
      const deltaX = e.changedTouches[0].clientX - touchStartX;
      if (deltaX > 50) {
        HandlePrev();
      } else if (deltaX < -50) {
        HandleNext();
      }
    }
    setTouchStartX(null);
    setTouchStartY(null);
    setIsSwiping(false);
  };

  const showButton = carouselWidth < (itemWidth + gap) * items.length;

  return (
    <div className={cn('wf-relative', className)}>
      <div
        className="wf-relative wf-flex wf-overflow-x-hidden"
        ref={carouselRef}>
        <div
          className="wf-flex wf-transition-transform wf-duration-500"
          style={{
            transform: `translateX(-${curIndex * (itemWidth + gap)}px)`,
            width: `${(itemWidth + gap) * items.length - gap}px`,
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}>
          {items.map((product, index) => (
            <div
              key={`${product.id}_${index}`}
              className={cn(
                'wf-relative wf-mb-3 wf-mt-[50px] wf-flex wf-min-h-[377px] wf-w-[250px] wf-flex-shrink-0 wf-flex-col wf-rounded wf-border wf-border-rdsn-gray-200 wf-bg-rdsn-white wf-p-4 wf-pt-[50.5px] wf-font-gothic wf-transition-transform wf-duration-300',
              )}
              style={{ marginRight: `${gap}px` }}>
              <div className="wf-absolute wf-left-1/2 wf-top-0 wf-h-[100px] -wf-translate-x-1/2 -wf-translate-y-1/2 wf-object-contain">
                <img
                  className="wf-h-full wf-w-auto wf-max-w-full"
                  src={product.image?.url}
                  alt={product.image?.alt}
                  fetchPriority="low"
                  loading="lazy"
                />
              </div>
              <div className="wf-relative wf-flex wf-flex-shrink-0 wf-flex-col wf-gap-y-2 wf-border-b wf-pb-6">
                <span className="wf-border-rdsn-gray-200 wf-text-rdsn-prod wf-leading-3">
                  {product.name}
                </span>
                <span className="wf-border-rdsn-gray-200 wf-text-rdsn-prod-sub wf-leading-[10px]">
                  {product.medicationName}
                </span>
              </div>

              <div className="wf-grid wf-w-full wf-auto-cols-fr wf-grid-flow-col wf-border-b wf-border-rdsn-gray-200 wf-py-3">
                {product.attributes?.map((item) => (
                  <div key={item.id} className="wf-flex wf-flex-col wf-gap-y-1">
                    <span className="wf-text-rdsn-body-sm wf-uppercase">
                      {item.title}
                    </span>
                    <span className="wf-px-1 wf-text-rdsn-body-sm wf-font-medium">
                      {item.description}
                    </span>
                  </div>
                ))}
              </div>

              <div className="wf-flex wf-flex-1 wf-border-b wf-border-rdsn-gray-200 wf-py-3">
                <ul className="wf-w-full">
                  {product.features?.map((item) => (
                    <li
                      key={item.id}
                      className="wf-mb-2 wf-flex wf-items-center wf-gap-2 last-of-type:wf-mb-0">
                      <div className="wf-flex wf-h-5 wf-w-5 wf-items-center wf-justify-center wf-rounded-full wf-bg-rdsn-black">
                        <CheckmarkIcon className="wf-h-[6.8px] wf-w-[9.4px] wf-text-rdsn-white" />
                      </div>
                      <div className="wf-flex-1 wf-text-rdsn-body-sm">
                        {item.title}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="wf-pb-6 wf-pt-3 wf-text-rdsn-cap wf-font-medium wf-tracking-[-0.02em]">
                {product.priceDescription}
              </div>

              <Button
                size="small"
                variant="primary"
                className="wf-text-rdsn-white"
                asChild>
                <a
                  href={
                    overrideProductsCallToActionUrl ||
                    product.callToAction?.url ||
                    ''
                  }>
                  {callToActionText || product.callToAction?.text}
                </a>
              </Button>

              {product.safetyInformationUrl ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wf-flex wf-flex-col wf-items-center wf-justify-end wf-pt-4 wf-text-rdsn-cap wf-tracking-[-0.02em] wf-underline"
                  href={product.safetyInformationUrl}>
                  Important safety information
                </a>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      {showButton && (
        <div className="wf-relative wf-flex wf-w-full wf-justify-start sm:wf-justify-end">
          <div className="wf-flex wf-gap-4 wf-px-6 wf-pt-4">
            <IconButton
              variant="secondary"
              aria-label="back"
              onClick={HandlePrev}
              disabled={curIndex === 0}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              variant="secondary"
              aria-label="next"
              onClick={HandleNext}>
              <ArrowRightIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

'use client';
import {
  CSSProperties,
  type ReactNode,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';

type RightExpandingContainerProps = {
  heightDesktop: string;
  children: ReactNode;
};

export const RightExpandingContainer = ({
  heightDesktop,
  children,
}: RightExpandingContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState('auto');
  const deferredMaxWidth = useDeferredValue(maxWidth);

  useEffect(() => {
    const calculateMaxWidth = () => {
      if (ref.current) {
        setMaxWidth(
          `calc(100vw - ${ref.current.getBoundingClientRect().left}px - 10px)`,
        );
      }
    };
    calculateMaxWidth();
    window.addEventListener('resize', calculateMaxWidth);
    return () => {
      window.removeEventListener('resize', calculateMaxWidth);
    };
  }, []);

  return (
    <div
      className="lg:wf-relative lg:wf-h-[var(--container-height)]"
      ref={ref}
      style={{
        '--container-height': heightDesktop,
      }}>
      <div
        className="lg:wf-absolute lg:wf-min-w-full lg:wf-max-w-[var(--container-max-width)] lg:wf-overflow-x-auto"
        style={{
          '--container-max-width': deferredMaxWidth,
        }}>
        {children}
      </div>
    </div>
  );
};

'use client';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { IconButton } from '@wearemotivated/design-system/redesign/components/IconButton';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';

type CardCarouselProps = {
  children: ReactNode[];
  scrollBy?: number;
  classNames?: {
    container?: string;
    scrollButtons?: string;
  };
};

export const CardCarousel = ({
  children,
  scrollBy = 150,
  classNames = {},
}: CardCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFirstCardVisible, setIsFirstCardVisible] = useState(true);
  const [isLastCardVisible, setIsLastCardVisible] = useState(true);
  const showScrollButtons = !isFirstCardVisible || !isLastCardVisible;

  useEffect(() => {
    const cards = containerRef.current?.children;
    if (cards) {
      const observer = new IntersectionObserver(
        (entries) => {
          const cards = containerRef.current?.children as HTMLCollection;
          entries.forEach((entry) => {
            if (entry.target === cards[0]) {
              setIsFirstCardVisible(entry.isIntersecting);
            } else if (entry.target === cards[cards.length - 1]) {
              setIsLastCardVisible(entry.isIntersecting);
            }
          });
        },
        {
          rootMargin: '100% 0% 100% 0%',
          threshold: 1,
        },
      );
      const firstCard = cards[0] as HTMLElement;
      const lastCard = cards[cards.length - 1] as HTMLElement;
      observer.observe(firstCard);
      firstCard !== lastCard && observer.observe(lastCard);
      return () => {
        observer.unobserve(firstCard);
        observer.unobserve(lastCard);
      };
    }
  }, []);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -scrollBy, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' });
    }
  };

  return (
    <>
      <div
        className={cn(
          'scrollbar-hide wf-flex wf-gap-4 wf-overflow-x-auto wf-overflow-y-hidden',
          classNames?.container,
        )}
        ref={containerRef}>
        {children}
      </div>
      <div
        className={cn(
          showScrollButtons ? 'wf-flex wf-gap-4' : 'wf-hidden',
          classNames?.scrollButtons,
        )}>
        <IconButton
          variant="secondary"
          disabled={isFirstCardVisible}
          onClick={scrollLeft}
          aria-label="Previous Card">
          <ArrowLeftIcon />
        </IconButton>
        <IconButton
          variant="secondary"
          disabled={isLastCardVisible}
          onClick={scrollRight}
          aria-label="Next Card">
          <ArrowLeftIcon className="wf-rotate-180" />
        </IconButton>
      </div>
    </>
  );
};

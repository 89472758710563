import { type CategorizedFaqCategory } from '@strapi-schema';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@wearemotivated/design-system/redesign/components/Accordion';

type CategoryFaqProps = {
  data: CategorizedFaqCategory;
};

export const CategoryFaq = ({ data }: CategoryFaqProps) => {
  if (!data.items?.length) {
    return null;
  }
  return (
    <Accordion
      defaultValue={String(data.items[0].id as number)}
      className="rdsn-fade-in wf-rounded wf-border wf-border-rdsn-gray-400 wf-p-8"
      key={data.id}>
      {(data.items ?? []).map((item) => (
        <AccordionItem key={item.id} value={String(item.id)}>
          <AccordionTrigger className="wf-text-rdsn-body [&]:wf-leading-none">
            {item.question}
          </AccordionTrigger>
          <AccordionContent className="accordion-content wf-text-rdsn-body-smd">
            {item.answer ?? ''}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

'use client';

import Link from 'next/link';
import type { HelpCenterItem as HelpCenterItemType } from '@strapi-schema';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { useIsLoggedIn } from '@/redesign/components/hooks/useIsLoggedIn';
import { getLoginUrl } from '@/redesign/lib/utils/client/auth';
import { HELP_CENTER_RETURN_TO_KEY } from '@/redesign/lib/constants';

type HelpCenterItemProps = {
  data: HelpCenterItemType;
};

export const HelpCenterItem = ({ data }: HelpCenterItemProps) => {
  const { isLoggedIn } = useIsLoggedIn();
  const href = isLoggedIn
    ? (data.targetValue ?? '')
    : getLoginUrl(HELP_CENTER_RETURN_TO_KEY);
  return (
    <Link
      href={href}
      className="wf-group wf-flex wf-items-center wf-justify-between wf-rounded wf-border wf-border-rdsn-gray-400 wf-px-4 wf-py-5 wf-text-rdsn-body wf-leading-none wf-transition-colors wf-duration-200 wf-ease-in-out hover:wf-border-rdsn-black">
      {data.buttonText}
      <div className="wf-inline-flex wf-h-8 wf-w-8 wf-items-center wf-justify-center wf-rounded-full wf-border wf-border-rdsn-subtleBorder wf-transition-colors wf-duration-200 wf-ease-in-out group-hover:wf-border-none group-hover:wf-bg-rdsn-black">
        <ArrowRightIcon className="group-hover:wf-text-rdsn-white" />
      </div>
    </Link>
  );
};

'use client';
import { ComponentsContainerData } from '@/redesign/api/strapi/types';
import { useEffect, useRef } from 'react';
import ResponsiveImage from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { useTailwindBreakpoint } from '@wearemotivated/design-system/redesign/hooks/useTailwindBreakpoint';

type ResponsiveVideoProps = Pick<
  ComponentsContainerData['homepageTopBanner'],
  'video' | 'fallbackImage'
>;

export const ResponsiveVideo = ({
  video,
  fallbackImage,
}: ResponsiveVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isDesktop = useTailwindBreakpoint('md', null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.play();
  }, [isDesktop]);

  if (isDesktop === null || (!video.desktop.url && !video.mobile?.url)) {
    return (
      <div className="max-md:wf-mx-auto max-md:wf-h-[125vw] max-md:wf-max-w-sm md:wf-absolute md:wf-inset-0 md:wf-flex md:wf-justify-center">
        <ResponsiveImage
          pictureClassName="max-md:wf-pt-6 max-md:wf-flex max-md:wf-w-full max-md:wf-h-full max-md:wf-justify-center max-md:wf-items-end"
          className="wf-object-contain md:wf-h-full md:wf-w-full md:wf-object-bottom"
          desktop={fallbackImage.desktop}
          mobile={fallbackImage.mobile}
          minWidthBreakpoint="md"
        />
      </div>
    );
  }

  const src = isDesktop
    ? video.desktop.url
    : (video.mobile?.url ?? video.desktop.url);
  return (
    <video
      className="wf-mx-auto wf-w-full wf-max-w-[1920px] max-md:wf-h-[125vw] max-md:wf-pt-6 md:wf-absolute md:wf-inset-0 md:wf-h-full md:wf-w-full"
      src={src}
      loop={true}
      playsInline={true}
      muted={true}
      ref={videoRef}
      preload="none"
    />
  );
};

import type { CategorizedFaqCategory } from '@strapi-schema';
import { cn } from '@/redesign/lib/utils/style';
import { ArrowRightBoldIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRightBold';

type CategoriesListProps = {
  data: CategorizedFaqCategory[];
  activeCategory: CategorizedFaqCategory;
  onCategoryChange: (category: CategorizedFaqCategory) => void;
};

export const CategoriesList = ({
  data,
  activeCategory,
  onCategoryChange,
}: CategoriesListProps) => {
  const handleCategoryClick = (category: CategorizedFaqCategory) => () => {
    onCategoryChange(category);
  };

  return (
    <ul className="wf-min-w-max wf-text-rdsn-body-lg wf-leading-normal">
      {data.map((category) => {
        const isActive = category.id === activeCategory.id;
        return (
          <li key={category.id} className={isActive ? 'wf-font-medium' : ''}>
            <button
              className="wf-inline-flex wf-items-center wf-gap-x-1"
              onClick={handleCategoryClick(category)}>
              <ArrowRightBoldIcon
                className={cn(
                  'wf-text-rdsn-black wf-transition-opacity wf-duration-100 wf-ease-[steps(1,end)]',
                  isActive ? 'wf-opacity-1' : 'wf-opacity-0',
                )}
              />
              <span
                className={cn(
                  'wf-transition-transform wf-duration-300 wf-ease-in-out',
                  !isActive ? '-wf-translate-x-[18px]' : '',
                )}>
                {category.name}
              </span>
            </button>
          </li>
        );
      })}
    </ul>
  );
};
